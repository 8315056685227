<div class="toast-container position-fixed bottom-0 end-0 p-3" style="z-index: 11">
    <!-- <div class="position-fixed bottom-0 end-0 p-3" style="z-index: 11"> -->
        <div *ngFor="let message of messages" id="liveToast" class="toast" [ngClass]="message.status" role="alert" aria-live="assertive" aria-atomic="true">
            <div class="toast-header">
                <strong *ngIf="message.type === 'error'" class="me-auto" style="color: gray;">{{ 'common.error_message' | translate }}</strong>
                <strong *ngIf="message.type === 'success'" class="me-auto" style="color: gray;">{{ 'common.message' | translate }}</strong>
                <!-- <small style="color: gray;">asd</small> -->
                <button type="button" class="btn-close" data-bs-dismiss="toast" aria-label="Close" (click)="closeToast(message)"></button>
            </div>
            <div class="toast-body" [ngStyle]="{'background-color': getBackgroundColor(message)}">
                <div class="row">
                    <div class="d-flex justify-content-between">
                        <p 
                            [ngStyle]="{
                                'overflow': message.isExpanded ? 'visible' : 'hidden',
                                'text-overflow': message.isExpanded ? '' : 'ellipsis',
                                'white-space': message.isExpanded ? 'normal' : 'nowrap',
                                'word-break': 'break-word'}"
                                (click)="message.isExpanded = !message.isExpanded">
                            {{ message.message | translate}}
                        </p>
                        <div *ngIf="message.type === 'error'" class="col-1">
                            <svg class="svg-fill" (click)="copyMessage(message)">
                                <use href="assets/svg/icon-sprite.svg#copy"></use>
                            </svg>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    <!-- </div> -->
</div>