<div class="container-fluid p-0">
    <div class="row">
        <div class="col-12">
            <div class="login-card" [style.background-image]="'url(' + defaulImageUrl + ')'">
                <div>
                    <div class="login-main">
                        <div>
                            <a class="logo" routerLink="/">
                            <img [src]="home.logoLightURL" alt="{{home.name}} logo" *ngIf="isImageLoaded" />
                            <img class="img-fluid for-light" src="assets/images/logo/logo.png" alt="loginpage"
                                *ngIf="!isImageLoaded" />
                            <img class="img-fluid for-dark" src="assets/images/logo/logo_dark.png" alt="loginpage"
                                *ngIf="!isImageLoaded" />
                            </a>
                        </div>
                        <form class="theme-form" [formGroup]="accountForm" (submit)="next()">
                            <h4>{{ 'authentication.operator.choose' | translate}}</h4>
                            <div class="form-group mt-4">
                                <select class="form-control" formControlName="account" (change)="onSelect($event)">
                                    <option *ngFor="let item of list" [value]="item.id">{{item.lastName + ' ' + item.firstName}}</option>
                                </select>
                            </div>
                            <div class="form-group mb-0">
                                <div class="text-end mt-4">
                                    <button class="btn btn-primary d-block w-100" [disabled]="!accountForm.valid" type="submit">
                                        <span>{{ 'authentication.operator.next' | translate}}</span>
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>