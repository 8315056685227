import { Menu } from "../model/menu.model"

export var MSPUserMenuItems: Menu[] = [
  {
    headTitle1: "MSP",
  },
  {
    title: "menu.dashboard",
    icon: "home",
    type: "link",
    path: "/msp-dashboard/msp",
    active: true,
  },
  {
    title: "menu.partners",
    type: "sub",
    badgeType: "light-success",
    active: false,
    icon: "contact",
    children: [
      { path: "/partners", title: "menu.companies", type: 'link', bookmark: true },
      { path: "/partner-groups", title: "menu.companyGroups", type: 'link', bookmark: true },
      { path: "/contacts", title: "menu.contacts", type: "link", bookmark: true },
      { path: "/partners/map", title: "menu.map", type: "link", bookmark: true }
    ]
  },
  {
    title: "Products",
    type: "sub",
    icon: "ecommerce",
    active: false,
    children: [
      { path: "msp-product/list", title: "menu.products", type: "link", bookmark: true },
      { path: "msp-product/schema", title: "menu.msp.schemas", type: "link", bookmark: true },
      { path: "materials/groups/list", title: "menu.msp.materialGroups", type: "link", bookmark: true },
      { path: "materials/subgroups/list", title: "menu.msp.materialSubGroups", type: "link" },
      {
        title: "menu.msp.properties", type: "sub", children: [
          { path: "materials/types/list", title: "menu.msp.materialTypes", type: "link" },
          { path: "materials/format/list", title: "menu.msp.materialFormats", type: "link" },
          { path: "materials/packing-format/list", title: "menu.msp.packingFormats", type: "link" },
          { path: "materials/colors/list", title: "menu.msp.colors", type: "link" },
          { path: "materials/vtsz/list", title: "menu.msp.VTSZ", type: "link" },
          { path: "materials/unit/list", title: "menu.msp.unit", type: "link" },
        ]
      },
    ]
  },
  {
    title: 'menu.msp.productMatching',
    path: "/product-matching/list",
    icon: 'search',
    type: 'link',
  },
  {
    title: 'menu.msp.simpleMatching',
    path: "/product-matching/simple",
    icon: 'search',
    type: 'link',
  },
  {
    title: "menu.technologist",
    type: 'link',
    icon: "learning",
    active: false,
    path: "/technologists/list",
  },
  {
    title: "menu.help",
    icon: "support-tickets",
    type: "sub",
    active: false,
    children: [
      {
        title: 'menu.support',
        path: "/support",
        icon: 'support-tickets',
        type: 'link',
      },
      {
        title: 'menu.impressum',
        path: "/impressum",
        icon: 'faq',
        type: 'link',
      },
    ],
  }
];

export var MSPAdminMenuItems: Menu[] = [
  {
    headTitle1: "MSP",
  },
  {
    title: "menu.dashboard",
    icon: "home",
    type: "link",
    path: "/msp-dashboard/msp",
    active: true,
  },
  {
    title: "menu.partners",
    type: "sub",
    badgeType: "light-success",
    active: false,
    icon: "contact",
    children: [
      { path: "/partners", title: "menu.companies", type: 'link', bookmark: true },
      { path: "/partner-groups", title: "menu.companyGroups", type: 'link', bookmark: true },
      { path: "/contacts", title: "menu.contacts", type: "link", bookmark: true },
      { path: "/partners/map", title: "menu.map", type: "link", bookmark: true }
    ]
  },
  {
    title: "Products",
    type: "sub",
    icon: "ecommerce",
    active: false,
    children: [
      { path: "msp-product/list", title: "menu.products", type: "link", bookmark: true },
      { path: "msp-product/schema", title: "menu.msp.schemas", type: "link", bookmark: true },
      { path: "materials/groups/list", title: "menu.msp.materialGroups", type: "link", bookmark: true },
      { path: "materials/subgroups/list", title: "menu.msp.materialSubGroups", type: "link" },
      {
        title: "menu.msp.properties", type: "sub", children: [
          { path: "materials/types/list", title: "menu.msp.materialTypes", type: "link" },
          { path: "materials/format/list", title: "menu.msp.materialFormats", type: "link" },
          { path: "materials/packing-format/list", title: "menu.msp.packingFormats", type: "link" },
          { path: "materials/colors/list", title: "menu.msp.colors", type: "link" },
          { path: "materials/vtsz/list", title: "menu.msp.VTSZ", type: "link" },
          { path: "materials/unit/list", title: "menu.msp.unit", type: "link" },
        ]
      },
    ]
  },
  {
    title: 'menu.msp.productMatching',
    path: "/product-matching/list",
    icon: 'search',
    type: 'link',
  },
  {
    title: 'menu.msp.simpleMatching',
    path: "/product-matching/simple",
    icon: 'search',
    type: 'link',
  },
  {
    title: "menu.technologist",
    type: 'link',
    icon: "learning",
    active: false,
    path: "/technologists/list",
  },
  {
    title: "menu.adminCenter",
    icon: "user",
    type: "sub",
    active: false,
    children: [
      { path: "users", title: "menu.users", type: "link" },
      { path: "audit/list", title: "menu.auditLogs", type: "link" },
      // { path: "b", title: "menu.settings", type: "link" },
      // { path: "a", title: "menu.migrations", type: "link" },
      { path: "backup/list", title: "menu.backups", type: "link" },
      { path: "health", title: "menu.health", type: "link" },
    ],
  },
  {
    title: "menu.help",
    icon: "support-tickets",
    type: "sub",
    active: false,
    children: [
      {
        title: 'menu.support',
        path: "/support",
        icon: 'support-tickets',
        type: 'link',
      },
      {
        title: 'menu.impressum',
        path: "/impressum",
        icon: 'faq',
        type: 'link',
      },
    ],
  }
];