import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'nodaDate',
})
export class NodaDatePipe implements PipeTransform {

  transform(value: any, format: string = 'yyyy-MM-dd'): string {
    if (!value) return '';

    const year = value.date.year;
    const month = value.date.month;
    const day = value.date.day;
    const hour = value.hour ?? '00';
    const minute = value.minute ?? '00';

    let formattedDate = format
      .replace('yyyy', year.toString())
      .replace('MM', month)
      .replace('dd', day)
      .replace('HH', hour.toString().padStart(2, '0'))
      .replace('mm', minute.toString().padStart(2, '0'));

    return formattedDate;
  }

}
