import { Menu } from "../model/menu.model"

export var FittingkerMenuItems: Menu[] = [
  {
    headTitle1: "Fittingker",
  },
  {
    title: "menu.dashboard",
    icon: "home",
    type: "link",
    path: "/fittingker-dashboard/fittingker",
    active: true,
  },
  {
    title: "Termékek",
    icon: "ecommerce",
    type: "link",
    path: "/fittingker-products/list",
    active: false
  },
  {
    title: "Erőforrások",
    icon: "social",
    type: "sub",
    active: false,
    children: [
      { path: "/fittingker-machines/list/machines", title: "Gépek", type: "link" },
      { path: "/fittingker-machines/list/partners", title: "Partnerek", type: "link" },
      { path: "/holiday", title: "Naptár", type: "link" },
      { path: "/capacities", title: "Kapacitások", type: "link" },
      {
        title: "Walter szerszámtár",
        type: "sub",
        badgeType: "light-success",
        badgeValue: "Új",
        icon: "task",
        active: false,
        children: [
          { path: "/walter-transaction/list", title: 'Tranzakciók', type: 'link' },
          { path: "/walter-transaction/items", title: 'Szerszámok', type: 'link' },
        ],
      },
    ],
  },
  {
    title: "Megrendelések",
    type: "link",
    icon: "task",
    active: false,
    path: "/fittingker-orders/list"
  },

  {
    title: "Gyártási feladatok",
    icon: "social",
    type: "sub",
    active: false,
    children: [
      {
        title: "Gyártási rendelések",
        type: "link",
        badgeType: "light-success",
        badgeValue: "Új",
        icon: "task",
        active: false,
        path: "/fittingker-production-orders/list"
      },
      { path: "/fittingker-mastertasks/list", title: "Mester tábla", type: "link" },
      { path: "/fittingker-machine-tasks/list", title: "Erőforrásonként", type: "link" },
      { path: "/fittingker-machines/gantt", title: "Operációk gépenként", type: "link" },
      { path: "/fittingker-machines/timeline", title: "Erőforrás idővonal", type: "link" },
      { path: "/fittingker-machine-tasks/issues", title: "Problémás operációk", type: "link" },
      { path: "/fittingker-material-requirements/list", title: "Anyagkövetelmények", type: "link",},
    ],
  },
  { icon: "task", path: "fittingker-import/schedule", title: "Ütemezés", type: "link" },
  {
    title: "Statisztikák",
    icon: "charts",
    active: false,
    type: "link",
    path: "/fittingker-statistics",
  },
  {
    title: "Importok",
    type: "sub",
    icon: "table",
    badgeType: "light-success",
    active: false,
    children: [
      { path: "/fittingker-import", title: "Mester import", type: "link" },
      { path: "/fittingker-import/cooperation", title: "Kooperációs import", type: "link" },
    ]
  },
  {
    title: "menu.adminCenter",
    icon: "user",
    type: "sub",
    active: false,
    children: [
      { path: "users", title: "menu.users", type: "link" },
      { path: "audit/list", title: "menu.auditLogs", type: "link" },
      { path: "backup/list", title: "menu.backups", type: "link" },
      { path: "health", title: "menu.health", type: "link" },
    ],
  },
  {
    title: "menu.help",
    icon: "support-tickets",
    type: "sub",
    active: false,
    children: [
      {
        title: 'menu.support',
        path: "/support",
        icon: 'support-tickets',
        type: 'link',
      },
      {
        title: 'menu.impressum',
        path: "/impressum",
        icon: 'faq',
        type: 'link',
      },
    ],
  }
];