import { Menu } from "../model/menu.model"

  export var ERPMenuItems: Menu[] = [
    {
      headTitle1: "ERP",
    },
    {
      title: "Dashboards",
      icon: "home",
      type: "link",
      path: "/msp-dashboard/msp",
      badgeType: "light-secondary",
      badgeValue: "In process",
      active: true,
    },
    {
      title: "Partners",
      type: "sub",
      badgeType: "light-success",
      badgeValue: "New",
      active: false,
      icon: "contact",
      children: [
        { path: "/partners", title: "Companies", type: 'link', bookmark: true },
        { path: "/partner-groups", title: "Company Groups", type: 'link', bookmark: true },
        { path: "/contacts", title: "Contacts", type: "link", bookmark: true },
        { path: "/partners/map", title: "Map", type: "link", bookmark: true }
      ]
    },
    {
      title: "Products",
      type: "sub",
      badgeType: "light-success",
      badgeValue: "New",
      icon: "ecommerce",
      active: false,
      children: [
        { path: "product/list", title: "Products", type: "link", bookmark: true },
        { path: "product/schema", title: "Schemas", type: "link", bookmark: true },
        { title: "Properties", type: "sub", children: [
          { path: "materials/groups/list", title: "Groups", type: "link", bookmark: true },
          { path: "materials/subgroups/list", title: "Sub Groups", type: "link" },
          { path: "materials/colors/list", title: "Colors", type: "link" },
          { path: "materials/types/list", title: "Material Types", type: "link"},
          { path: "materials/format/list", title: "Material Formats", type: "link"},
          { path: "materials/packing-format/list", title: "Packing Formats", type: "link"},
          { path: "materials/vtsz/list", title: "VTSZ", type: "link"},
          { path: "materials/unit/list", title: "Unit", type: "link"},
        ]},
      ]
    },
    {
      title: "MSP-Products",
      type: "sub",
      badgeType: "light-success",
      badgeValue: "New",
      icon: "ecommerce",
      active: false,
      children: [
        { path: "msp-product/list", title: "Products", type: "link", bookmark: true },
        { path: "msp-product/schema", title: "Schemas", type: "link", bookmark: true },
        { title: "Properties", type: "sub", children: [
          { path: "materials/groups/list", title: "Groups", type: "link", bookmark: true },
          { path: "materials/subgroups/list", title: "Sub Groups", type: "link" },
          { path: "materials/colors/list", title: "Colors", type: "link" },
          { path: "materials/types/list", title: "Material Types", type: "link"},
          { path: "materials/format/list", title: "Material Formats", type: "link"},
          { path: "materials/packing-format/list", title: "Packing Formats", type: "link"},
          { path: "materials/vtsz/list", title: "VTSZ", type: "link"},
          { path: "materials/unit/list", title: "Unit", type: "link"},
        ]},
      ]
    },
    {
      title: 'Product Matching',
      path: "/product-matching/list",
      // badgeType: "light-secondary",
      // badgeValue: "In Proc",
      icon: 'search',
      type: 'link',
    },
    {
      title: "Orders",
      type: 'link',
      badgeType: "light-success",
      badgeValue: "New",
      icon: "task",
      active: false,
      path: "/orders",
    },


    // {
    //   title: "Sales",
    //   icon: "ecommerce",
    //   badgeType: "light-secondary",
    //   badgeValue: "In process",
    //   type: "sub",
    //   active: false,
    //   children: [
    //     { path: "b", title: "Orders", type: "link" },
    //     { path: "b", title: "Customers", type: "link" },
    //     { path: "b", title: "Products", type: "link" },
    //     { path: "b", title: "Priceing", type: "link" },
    //   ],
    // },
    // {
    //   title: "Logistics",
    //   icon: "maps",
    //   badgeType: "light-secondary",
    //   badgeValue: "In process",
    //   type: "sub",
    //   active: false,
    //   children: [
    //     { path: "b", title: "Logistics Partners", type: "link" },
    //     { path: "b", title: "Transports", type: "link" },
    //   ],
    // },
    // {
    //   title: "Finance",
    //   icon: "sample-page",
    //   badgeType: "light-secondary",
    //   badgeValue: "In process",
    //   type: "sub",
    //   active: false,
    //   children: [
    //     { path: "b", title: "Accounts Receivable", type: "link" },
    //     { path: "b", title: "Accounts Payable", type: "link" },
    //     { path: "b", title: "General Ledger", type: "link" },
    //     { path: "b", title: "Financial Reports", type: "link" },
    //   ],
    // },
    // {
    //   title: "Inventory",
    //   icon: "others",
    //   badgeType: "light-secondary",
    //   badgeValue: "In process",
    //   type: "sub",
    //   active: false,
    //   children: [
    //     { path: "b", title: "Stock Management", type: "link" },
    //     { path: "b", title: "Warehouses", type: "link" },
    //     { path: "b", title: "Suppliers", type: "link" }
    //   ],
    // },
    // {
    //   title: "Procurement",
    //   icon: "form",
    //   badgeType: "light-secondary",
    //   badgeValue: "In process",
    //   type: "sub",
    //   active: false,
    //   children: [
    //     { path: "b", title: "Purchase Orders", type: "link" },
    //     { path: "b", title: "Suppliers", type: "link" },
    //     { path: "b", title: "Requisitions", type: "link" }
    //   ],
    // },
    // {
    //   title: "HR",
    //   icon: "user",
    //   badgeType: "light-secondary",
    //   badgeValue: "In process",
    //   type: "sub",
    //   active: false,
    //   children: [
    //     { path: "b", title: "Employee Management", type: "link" },
    //     { path: "b", title: "Payroll", type: "link" },
    //     { path: "b", title: "Attendance", type: "link" },
    //     { path: "b", title: "Training", type: "link" }
    //   ],
    // },
    // {
    //   title: "CRM",
    //   icon: "learning",
    //   badgeType: "light-secondary",
    //   badgeValue: "In process",
    //   type: "sub",
    //   active: false,
    //   children: [
    //     { path: "b", title: "Leads", type: "link" },
    //     { path: "b", title: "Contacts", type: "link" },
    //     { path: "b", title: "Opportunities", type: "link" }
    //   ],
    // },
    {
      title: "Riports",
      icon: "charts",
      badgeType: "light-secondary",
      badgeValue: "In process",
      type: "sub",
      active: false,
      children: [
        { path: "/chart/apex", title: "Sales Riport", type: "link" },
        { path: "/chart/google", title: "Inventory Reports", type: "link" },
        { path: "/chart/chartjs", title: "Financial Riports", type: "link" },
        { path: "/chart/chartist", title: "Costum Reports", type: "link" },
      ],
    },    
   
  ];